import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private requestService: RequestService) {}

  getInvoices(data?: any) {
    return this.requestService
      .getJSON(`/invoices`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  getInvoicesReport(data?: any) {
    return this.requestService
      .getJSON(`/invoices/report`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getInvoiceStatistic(data: any) {
    return this.requestService
      .getJSON(`/invoices/statistic`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getInvoice(id: string) {
    return this.requestService
      .getJSON(`/invoices/${id}`, { is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  postInvoice(data: any) {
    return this.requestService
      .postJSON(`/invoices`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  patchInvoiceMarkAsPaid(id: string, data: any) {
    return this.requestService
      .patchJSON(`/invoices/${id}/mark-as-paid`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  patchInvoice(id: string, data: any) {
    return this.requestService
      .patchJSON(`/invoices/${id}`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getSettingValue(name: string) {
    return this.requestService
      .getJSON(`/settings/name/${name}`, { is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  createInvoiceMoveIn(data: any) {
    return this.requestService
      .postJSON(`invoices/move-in`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  rejectPayment(_id: string, data: any) {
    return this.requestService.patchJSON(`/invoices/${_id}/reject-payment`, {
      data: data,
      is_loading: true,
    });
  }

  receivePayment(_id: string, data?: any) {
    return this.requestService.patchFile(`/invoices/${_id}/receive-payment`, {
      data: data,
      is_loading: true,
    });
  }

  moveOut(data: any) {
    return this.requestService.postJSON(`/invoices/move-out`, {
      data,
      is_loading: true,
    });
  }

  generateInvoice(data: any) {
    return this.requestService.postJSON(`/invoices/generate-from-records`, {
      data,
      is_loading: true,
    });
  }

  deleteInvoice(_id: string) {
    return this.requestService.deleteJSON(`/invoices/${_id}`, {
      is_loading: true,
    });
  }

  cancelInvoice(_id: string) {
    return this.requestService.patchJSON(`/invoices/${_id}/cancelled`, {
      is_loading: true,
    });
  }
}
