import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-no-file',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './no-file.component.html',
  styleUrl: './no-file.component.scss',
})
export class NoFileComponent {
  @Input() enableClick: boolean;
  @Input() hideMsg: boolean;
  @Output() onClick: EventEmitter<void> = new EventEmitter();

  clickHandler() {
    this.onClick.emit();
  }
}
