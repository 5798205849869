import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculateDatePipe } from './pipes/date';
import { MonthFormat } from './pipes/month';
import { DateAgoPipe } from './pipes/dateAgo';

@NgModule({
  declarations: [CalculateDatePipe, DateAgoPipe],
  imports: [
    CommonModule,
    MonthFormat
  ],
  exports: [
    CalculateDatePipe,
    MonthFormat,
    DateAgoPipe
  ]
})
export class DateModule {
}
