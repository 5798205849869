import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { RequestService } from './request.service';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AppFile } from "../models/responses/app-file";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private baseUrl = '';
  private fileUrl = ''

  constructor(private http: HttpClient,
              private requestService: RequestService) {
  }

  getFile(fileId: string) {
    return lastValueFrom<Blob>(
      this.requestService
        .getFile(`files/${ fileId }`, {
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const req = new HttpRequest('POST', `${ environment.api_url }/files/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  uploadPromise(file: File) {
    return lastValueFrom(
      this.requestService.postFile<any>(`files/upload`, {
        data: {
          file: file,
        },
        is_loading: true,
      })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  deleteFile(fileId: string) {
    return lastValueFrom(
      this.requestService
        .deleteJSON(`files/${ fileId }`, {is_loading: true})
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  getFiles(): Observable<any> {
    return this.http.get(`${ this.baseUrl }/files`);
  }

  getUrlFile(id: string) {
    let url = this.requestService.getUrl(`/files/${ id }`);
    return url
  }

  public getUrl(path: string) {
    let arr = path.split("/").filter((v) => v);
    arr.unshift(environment.api_url);
    return arr.join("/");
  }
}
