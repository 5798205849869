import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { AppFile } from "../../models/responses/app-file";
import { FileUploadService } from "../../services/file-upload.service";
import { saveAs } from 'file-saver';
import { MatRipple } from "@angular/material/core";

@Component({
  selector: 'app-file-item',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatRipple],
  templateUrl: './file-item.component.html',
  styleUrl: './file-item.component.scss',
})
export class FileItemComponent {
  @Input() file: File;
  @Input() allowView?: boolean;
  @Input() allowDownload?: boolean;
  @Input() allowDelete?: boolean;
  @Input() fit?: boolean;
  @Input() borderLess?: boolean;
  @Input() noHeight?: boolean;
  @Output() onDeleteClick: EventEmitter<void> = new EventEmitter();

  constructor(private _fileService: FileUploadService) {
  }

  // view = async () => {
  //   try {
  //     const file = await this._fileService.getFile(this.file._id);
  //     if (file) {
  //       const url = window.URL.createObjectURL(file);
  //       window.open(url, '_blank');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // download = async () => {
  //   try {
  //     const blob = await this._fileService.getFile(this.file._id);
  //     saveAs(blob, this.file.filename);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  delete() {
    this.onDeleteClick.emit();
  }

  getFileSize(sizeInBytes: number) {
    return (sizeInBytes * Math.pow(10, -6)).toFixed(2);
  }
}
