import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'app-check-expiring-status',
  templateUrl: './check-expiring-status.component.html',
  styleUrls: ['./check-expiring-status.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule
  ]
})
export class CheckExpiringStatusComponent implements OnChanges {
  @Input() endDate: Date;
  contractStatus: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.endDate && this.endDate) {
      this.contractStatus = this.getContractStatus();
    }
  }

  private getContractStatus(): string {
    const endDateMillis = new Date(this.endDate).getTime();
    const currentDateMillis = Date.now();
    let daysDifference = (endDateMillis - currentDateMillis) / (1000 * 60 * 60 * 24);
    // console.log(daysDifference);
    if (daysDifference < 0) {
      return 'expired';
    } else if (daysDifference < 60) {
      return 'almostExpired';
    }
    return 'valid';
  }
}
