<div
		class = "no-file"
		(click) = "enableClick ? clickHandler() : null"
		[class.clickable] = "enableClick"
>
	<img src = "assets/imgs/add_files.svg" alt = "" srcset = ""/>
	@if (!hideMsg) {
		<p class = "info">{{ "no_docs" | translate }}</p >
	}
	@if (enableClick) {
		<p class = "instruction">
			{{ "instructions.click_to_upload" | translate }}
		</p >
	}
</div >
