import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'monthFormat',
  pure: false,
  standalone: true
})
export class MonthFormat implements PipeTransform {
  private monthFull: string[] = [];
  private monthShort: string[] = [];

  constructor(private translate: TranslateService) {
    this.loadMonthNames();
    this.translate.onLangChange.subscribe(() => {
      this.loadMonthNames();
    });
  }

  transform(value: number, format: string = 'MMMM'): string {
    if (value < 1 || value > 12) {
      return 'Not found';
    }
    switch (format) {
      case 'MMMM':
        return this.monthFull[value - 1];
      case 'MMM':
        return this.monthShort[value - 1];
      case 'MM':
        return value.toString().padStart(2, '0');
      case 'M':
        return value.toString();
      default:
        return 'Not found';
    }
  }

  private loadMonthNames() {
    this.monthFull = [
      this.translate.instant('January'),
      this.translate.instant('February'),
      this.translate.instant('March'),
      this.translate.instant('April'),
      this.translate.instant('May'),
      this.translate.instant('June'),
      this.translate.instant('July'),
      this.translate.instant('August'),
      this.translate.instant('September'),
      this.translate.instant('October'),
      this.translate.instant('November'),
      this.translate.instant('December'),
    ];
    this.monthShort = [
      this.translate.instant('Jan'),
      this.translate.instant('Feb'),
      this.translate.instant('Mar'),
      this.translate.instant('Apr'),
      this.translate.instant('May'),
      this.translate.instant('Jun'),
      this.translate.instant('Jul'),
      this.translate.instant('Aug'),
      this.translate.instant('Sep'),
      this.translate.instant('Oct'),
      this.translate.instant('Nov'),
      this.translate.instant('Dec'),
    ];
  }
}
