export enum LocalStorageEnum {
  token = '_t',
  token_expires_at = '_tea',
  user_id = '_u',
  user_profile = '_uf',
  user_firstname = '_ufn',
  user_lastname = '_uln',
  user_role = '_ur',
  refresh_token = '_rft',
  location_id = '_l',
  organization_id = '_o',
  lang = 'lang',
  min_price = 'min_price',
  max_price = 'max_price',
  subscription_start = 'sub_start',
  subscription_end = 'sub_end',
  setup_status = 'setup_status',
}
