<div class = "amount-contract">
  <span *ngIf = "contractStatus === 'almostExpired'" class = "text-warning fs-6 fw-bolder">
  {{ '(' }} {{ 'near expire' | translate }} {{ ')' }}
  </span >
	<span *ngIf = "contractStatus === 'expired'" class = "text-danger fs-6 fw-bolder">
  {{ '(' }} {{ 'expired' | translate }} {{ ')' }}
  </span >
	<span *ngIf = "contractStatus === 'valid'" class = "text-success fs-6 fw-bolder">
  </span >
</div >
