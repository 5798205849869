<div class = "file-item" [class.fit] = "fit" [class.border-less] = "borderLess" [class.noHeight] = "noHeight">
	<img src = "" class = "icon" src = "assets/imgs/icons8-excel-48.png" alt = "adobe image"/>
	<div class = "file-info display-flex">
		<p class = "name fs-5 mb-0">{{ file.name }}</p >
		<p class = "size fs-6">{{ getFileSize(file.size) }} MB</p >
	</div >
	<div class = "action">
		@if (allowView) {
			<button
					type = "button"
					class = "view-action-btn"
					mat-mini-fab
					matRipple
					color = "primary"
			>
				<mat-icon >visibility</mat-icon >
			</button >
		}
		@if (allowDownload) {
			<button
					type = "button"
					class = "view-action-btn download"
					mat-mini-fab
					matRipple
					color = "primary"
			>
				<mat-icon >download</mat-icon >
			</button >
		}
		@if (allowDelete) {
			<button
					type = "button"
					class = "view-action-btn"
					style = "color: white"
					mat-mini-fab
					matRipple
					color = "warn"
					(click) = "delete()"
			>
				<mat-icon >delete_forever</mat-icon >
			</button >
		}
	</div >
</div >
